<template>
  <div>
    <div id="scroll-top"></div>
    <div id="the-outer-document" style="font-family: 'Poppins', sans-serif">
      <link
        href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swadp"
        rel="stylesheet"
      />
      <div
        v-for="(page, index) in contractPages"
        class="contract-table"
        :id="`doc-${page.id}`"
        :key="index"
      >
        <TravelContractDocumentPage
          v-if="(page.id != 9 || page.id != 10) && contractPages[index].check"
          :contract="contract"
          :page="page"
          :contrato="contrato"
        />
        <CreditCardDocument
          v-if="page.id == 9 && contractPages[index].check"
          :contract="contract"
          :page="page"
        />
        <ThirdPartyDocument
          v-if="page.id == 10 && contractPages[index].check"
          :contract="contract"
          :page="page"
        />
      </div>

      <v-style class="none">
        #the-outer-document { line-height: 0 } p { margin-top: 0; margin-bottom:
        1em; } table { border-collapse: collapse; } table { text-indent:
        initial; border-spacing: 2px; } .ql-align-center { text-align: center; }
        table td { color: #666; font-size: 13px; line-height: 14px; } b, strong
        { font-weight: 600 !important; } .ql-align-justify { text-align:
        justify; }
      </v-style>
    </div>
    <span id="to-top"><a-icon type="up-circle" @click="toTop" /></span>
  </div>
</template>

<script>
import jq from "jquery";

import CreditCardDocument from "../documents/v2/CreditCardDocument.vue";
import ThirdPartyDocument from "../documents/v2/ThirdPartyDocument.vue";
import TravelContractDocumentPage from "../documents/v2/TravelContractDocumentPage.vue";

export default {
  name: "ViewContractPreviewDocumentsSection",
  components: {
    TravelContractDocumentPage,
    CreditCardDocument,
    ThirdPartyDocument,
  },
  props: {
    contract: Object,
    contrato: Object,
    contractPages: Array,
  },
  data() {
    return {
      loadingDocuments: false,
    };
  },
  mounted() {
    this.getDocuments();
  },
  methods: {
    getDocuments() {
      this.loadingDocuments = true;

      let documents =
        this.contract.meta.contract_documents != undefined
          ? JSON.parse(this.contract.meta.contract_documents)
          : [];

      this.$http
        .get(`/document/list?page=1&per_page=100&id=${documents}`)
        .then(({ data }) => {
          const contractPages = data.data.map((doc) => {
            return { ...doc, check: true };
          });

          this.systemDocs();
          this.$emit("updateContractPages", contractPages);
          this.loadingDocuments = false;
        });
    },
    toTop() {
      jq(".document-viewer").animate(
        {
          scrollTop: document.getElementById("scroll-top").offsetTop - 60,
        },
        500
      );
    },
    systemDocs() {
      let docs = this.contract.meta.contract_documents
        ? JSON.parse(this.contract.meta.contract_documents)
        : [];

      if (docs.length > 0) {
        docs.forEach((document) => {
          if (
            document == "7" ||
            document == "8" ||
            document == "9" ||
            document == "10"
          ) {
            let title = "";
            let subpages = [];

            let creditCards =
                this.contract.meta.credit_card_rows != undefined
                  ? JSON.parse(this.contract.meta.credit_card_rows)
                  : [],
              mapedCreditCards = [];

            if (creditCards.length > 0) {
              mapedCreditCards = creditCards.map((cCard) => {
                return { ...cCard, check: true };
              });
            }

            if (document == "9") {
              title = "Aut. de Débito";
              subpages = mapedCreditCards; // this.contrato.pagamento.credito.cartao
            }

            let thirdParty =
                this.contract.meta.third_party_payment_rows != undefined
                  ? JSON.parse(this.contract.meta.third_party_payment_rows)
                  : [],
              mapedThirdParty = [];

            if (thirdParty.length > 0) {
              mapedThirdParty = thirdParty.map((tParty) => {
                return { ...tParty, check: true };
              });
            }

            if (document == "10") {
              title = "Anexo II - Terceiros";
              subpages = mapedThirdParty; //  this.contrato.pagamento.terceiros.lista
            }

            if (document == "8") {
              title = "VR - Anexo - Produtos";
            }

            if (document == "7") {
              title = "VR - Contrato 2021";
            }

            this.contractPages.push({
              id: document,
              title: title,
              subtitle: "",
              data: [],
              check: true,
              subpages,
            });
          }
        });
      }

      // SORT PAGES BASED ON SELECTED DOCS

      if (this.contract.meta.contract_documents != undefined) {
        const sorter = (a, b) => {
          return (
            JSON.parse(this.contract.meta.contract_documents).indexOf(a.id) -
            JSON.parse(this.contract.meta.contract_documents).indexOf(b.id)
          );
        };

        this.contractPages.sort(sorter);
      }
    },
  },
};
</script>

<style lang="sass" scoped>
#to-top
  position: absolute
  bottom: 10px
  right: 30px
  font-size: 35px
  cursor: pointer
  color: #fff
  opacity: 0.3
  transition: .3s
  &:hover
    opacity: 1
#the-outer-document
  max-width: 793px
  margin: 0 auto
</style>
