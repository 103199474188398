import { render, staticRenderFns } from "./ViewContractPreviewDocumentsSection.vue?vue&type=template&id=4a56b3cd&scoped=true&"
import script from "./ViewContractPreviewDocumentsSection.vue?vue&type=script&lang=js&"
export * from "./ViewContractPreviewDocumentsSection.vue?vue&type=script&lang=js&"
import style0 from "./ViewContractPreviewDocumentsSection.vue?vue&type=style&index=0&id=4a56b3cd&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a56b3cd",
  null
  
)

export default component.exports